import React, { useState } from "react"
import {Container,Button,ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import LayoutOrig from "../../components/layout_fr"
import Seo from "../../components/seo"




const Prices = () => {

    const [isChecked, setIsChecked] = useState(false);

    const handleToggle = () => setIsChecked(!isChecked);


    return(
        <LayoutOrig>

            <Seo
                titleTemplate={`Tarifs : des offres adaptées à vos besoins | Trezorino`}
                title="Trezorino : Des offres adaptées pour optimiser votre trésorerie."
                description="Choisissez l'offre la plus adaptée à votre entreprise, que vous soyez un indépendant, un freelance, une TPE ou une PME..."
                lang="fr"
            />
            <Container>
                <div className="container-fluid mt-5">
                    <div className="container p-5">
                        <h1 className="text-center mt-4 mb-6">Des offres adaptées pour optimiser votre trésorerie.</h1>
                        <div  className="mb-4" style={{width:"20%", borderRadius:"100px"}}>
                            <ToggleButtonGroup className="switchbuttongroup" name="pricing" type="radio" defaultValue="true" onChange={handleToggle}>
                                <ToggleButton id="year" className={true ? "switchbutton-active" : "switchbutton"}  value="true">Annuel</ToggleButton>
                                <ToggleButton id="month" className={true ? "switchbutton-active" : "switchbutton"} value="false">Mensuel</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <div className="row">

                            <div className="col-lg-4 col-md-12 mb-4">
                                <div className="card h-100 shadow-lg" style={{borderTopColor:"blue", borderWidth:"20px"}}>
                                    <div className="card-body">

                                        <div className="text-center p-3">
                                            <div className="card-title" style={{ fontSize:"38px"}}>Starter
                                            </div>
                                            <p className="card-text">Tout ce qu'il faut pour commencer à bien gérer sa trésorerie.</p>
                                            <span style={{ fontSize:"60px"}}>{isChecked ? 73 : 61}</span><span style={{ fontSize:"24px"}}> €</span> /mois H.T
                                        </div>

                                    </div>
                                    <div className="card-body text-center">
                                        <Button  variant="try"  style={{width:"200px",fontSize:"14.4px", fontWeight:"500"}} href={`${process.env.APP_URL}/signup`}>Essai gratuit 14j</Button>
                                    </div>
                                    <ul className="pt-4" style={{fontSize:"14px"}}>
                                        <li className="checkGreen"><p><span style={{color:"blue"}}>1</span> banque connectée</p></li>
                                        <li className="checkGreen"><p>Suivi des soldes de banque</p></li>
                                        <li className="checkGreen"><p><span style={{color:"blue"}}>1</span> utilisateur</p></li>
                                        <li className="checkGreen"><p><span style={{color:"blue"}}>1</span> scénario</p></li>
                                        <li className="checkGreen"><p>Centralisation des factures</p></li>
                                        <li className="checkGreen"><p>Catégorisation automatique</p></li>
                                        <li className="checkGreen"><p>Rapprochement bancaire</p></li>
                                        <li className="checkGreen"><p>Saisie intelligente des factures : <span style={{color:"blue"}}>50</span> factures / mois</p></li>
                                        <li className="checkGreen"><p>Import Excel des factures</p></li>
                                        <li className="checkGreen"><p>Tableau de trésorerie</p></li>
                                        <li className="checkGreen"><p>Gestion des prévisions</p></li>
                                        <li className="checkGreen"><p>Accès au support en ligne</p></li>
                                        <li className="close"><p>Gestionnaire de compte dédié</p></li>
                                        <li className="close"><p>Multi devise</p></li>
                                        <li className="close"><p>Gestion multi entité</p></li>
                                        <li className="close"><p>Gestion des droits utilisateurs</p></li>
                                        <li className="close"><p>Intégration automatique à des logiciels externes</p></li>
                                        <li className="close"><p>Rendez-vous personnalisé de prise en main</p></li>

                                    </ul>
                                    <div className="card-body text-center">
                                        <Button  variant="demo"  className="header-nav-button" style={{width:"200px",fontSize:"14.4px"}} href={`${process.env.APP_URL}/signup`}>Choisir le plan Starter</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 mb-4">
                                <div className="card h-100 shadow-lg" style={{borderTopColor:"blue", borderWidth:"20px"}}>
                                    <div className="card-body">

                                        <div className="text-center p-3">
                                            <div className="card-title" style={{ fontSize:"38px"}}>Pro
                                            </div>
                                            <p className="card-text">Pour les petites entreprises dynamiques qui souhaitent piloter leur trésorerie.</p>
                                            <span style={{ fontSize:"60px"}}>{isChecked ? 121 : 101}</span><span style={{ fontSize:"24px"}}> €</span> /mois H.T
                                        </div>

                                    </div>
                                    <div className="card-body text-center">
                                        <Button  variant="try"  style={{width:"200px",fontSize:"14.4px", fontWeight:"500"}} href={`${process.env.APP_URL}/signup`}>Essai gratuit 14j</Button>
                                    </div>
                                    <ul className="pt-4" style={{fontSize:"14px"}}>
                                        <li className="checkGreen"><p><span style={{color:"blue"}}>2</span> banques connectées</p></li>
                                        <li className="checkGreen"><p>Suivi des soldes de banque</p></li>
                                        <li className="checkGreen"><p><span style={{color:"blue"}}>5</span> utilisateurs</p></li>
                                        <li className="checkGreen"><p><span style={{color:"blue"}}>5</span> scénarios</p></li>
                                        <li className="checkGreen"><p>Centralisation des factures</p></li>
                                        <li className="checkGreen"><p>Catégorisation automatique</p></li>
                                        <li className="checkGreen"><p>Rapprochement bancaire</p></li>
                                        <li className="checkGreen"><p>Saisie intelligente des factures : <span style={{color:"blue"}}>100</span> factures / mois</p></li>
                                        <li className="checkGreen"><p>Import Excel des factures</p></li>
                                        <li className="checkGreen"><p>Tableau de trésorerie</p></li>
                                        <li className="checkGreen"><p>Gestion des prévisions</p></li>
                                        <li className="checkGreen"><p>Accès au support en ligne</p></li>
                                        <li className="checkGreen"><p>Gestionnaire de compte dédié</p></li>
                                        <li className="checkGreen"><p>Multi devise</p></li>
                                        <li className="checkGreen"><p>Gestion multi entité : <span style={{color:"blue"}}>2</span> sociétés</p></li>
                                        <li className="checkGreen"><p>Gestion des droits utilisateurs</p></li>
                                        <li className="close"><p>Intégration automatique à des logiciels externes</p></li>
                                        <li className="close"><p>Rendez-vous personnalisé de prise en main</p></li>
                                    </ul>
                                    <div className="card-body text-center">
                                        <Button  variant="demo"  className="header-nav-button" style={{width:"200px",fontSize:"14.4px"}} href={`${process.env.APP_URL}/signup`}>Choisir le plan Pro</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 mb-4">
                                <div className="card h-100 shadow-lg" style={{borderTopColor:"blue", borderWidth:"20px"}}>
                                    <div className="card-body">

                                        <div className="text-center p-3">
                                            <div className="card-title" style={{ fontSize:"38px"}}>Entreprise
                                            </div>
                                            <p className="card-text">Un service sur mesure pour les petites et les moyennes entreprises.</p>
                                            <span style={{ fontSize:"60px"}}>{isChecked ? 301 : 251}</span><span style={{ fontSize:"24px"}}> €</span> /mois H.T
                                        </div>

                                    </div>
                                    <div className="card-body text-center">
                                        <Button  variant="try"  style={{width:"200px",fontSize:"14.4px", fontWeight:"500"}} href={`${process.env.APP_URL}/signup`}>Essai gratuit 14j</Button>
                                    </div>
                                    <ul className="pt-4" style={{fontSize:"14px"}}>
                                        <li className="checkGreen"><p><span style={{color:"blue"}}>3</span> banques connectées</p></li>
                                        <li className="checkGreen"><p>Suivi des soldes de banque</p></li>
                                        <li className="checkGreen"><p><span style={{color:"blue"}}>10</span> Utilisateurs</p></li>
                                        <li className="checkGreen"><p><span style={{color:"blue"}}>10</span> scénarios</p></li>
                                        <li className="checkGreen"><p>Centralisation des factures</p></li>
                                        <li className="checkGreen"><p>Catégorisation automatique</p></li>
                                        <li className="checkGreen"><p>Rapprochement bancaire</p></li>
                                        <li className="checkGreen"><p>Saisie intelligente des factures : <span style={{color:"blue"}}>350</span> factures / mois</p></li>
                                        <li className="checkGreen"><p>Import Excel des factures</p></li>
                                        <li className="checkGreen"><p>Tableau de trésorerie</p></li>
                                        <li className="checkGreen"><p>Gestion des prévisions</p></li>
                                        <li className="checkGreen"><p>Accès au support en ligne</p></li>
                                        <li className="checkGreen"><p>Gestionnaire de compte dédié</p></li>
                                        <li className="checkGreen"><p>Multi devise</p></li>
                                        <li className="checkGreen"><p>Gestion multi entité : <span style={{color:"blue"}}>3</span> sociétés</p></li>
                                        <li className="checkGreen"><p>Gestion des droits utilisateurs</p></li>
                                        <li className="checkGreen"><p>Intégration automatique à des logiciels externes</p></li>
                                        <li className="checkGreen"><p>Rendez-vous personnalisé de prise en main</p></li>
                                    </ul>
                                    <div className="card-body text-center">
                                        <Button  variant="demo"  className="header-nav-button" style={{width:"200px",fontSize:"14.4px"}} href={`${process.env.APP_URL}/signup`}>Choisir le plan Entreprise</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </LayoutOrig>
    )
}

export default Prices;
